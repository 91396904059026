import { FixedIcons } from "../components/FixedIcons/FixedIcons";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { Main } from "../components/Main/Main";

const Home = () => {
  return (
    <>
      <Header />
      <Main />
      <FixedIcons />
      <Footer />
    </>
  );
};

export { Home };
